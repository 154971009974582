import {ActionType} from './action';

const initialState = {
  dumaNews: [],
  regionNews: [],
  newsById: {},
  isLoading: false,
  activeNewsPage: 1,
  countNewsPages: 1,
  grats: [],
  gratsByYear: [],
  searchResult: [],
  searchQuery: ``,
  activeSearchPage: 1,
  countSearchPages: 1,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.LOAD_STARTED:
      return {
        ...state,
        isLoading: action.payload,
      };

    case ActionType.LOAD_DUMA_NEWS:
      return {
        ...state,
        dumaNews: action.payload,
      };

    case ActionType.LOAD_REGION_NEWS:
      return {
        ...state,
        regionNews: action.payload,
      };

    case ActionType.LOAD_NEWS_BY_ID:
      return {
        ...state,
        newsById: action.payload,
      };

    case ActionType.LOAD_NEWS_PAGES_COUNT:
      return {
        ...state,
        activeNewsPage: action.payload.activeNewsPage,
        countNewsPages: action.payload.countNewsPages,
      };

    case ActionType.LOAD_GRATS:
      return {
        ...state,
        grats: action.payload,
      };

    case ActionType.LOAD_GRATS_BY_YEAR:
      return {
        ...state,
        gratsByYear: action.payload,
      };

    case ActionType.LOAD_SEARCH_RESULT:
      return {
        ...state,
        searchResult: action.payload,
      };
    
    case ActionType.GET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload
      };

    case ActionType.LOAD_SEARCH_PAGES_COUNT:
      return {
        ...state,
        activeSearchPage: action.payload.activeSearchPage,
        countSearchPages: action.payload.countSearchPages,
      };

    default:
      return state;
  }
};

export default reducer;
