import React from 'react';
import MetaTags from 'react-meta-tags';
import Header from '../../blocks/header/header';
import Footer from '../../blocks/footer/footer';
import LoadingSpinner from '../../elements/loading-spinner/loading-spinner';

const LoadingPage = () => {

  return (
    <>
      <MetaTags>
        <meta name="description" content="Официальный сайт Чижова Сергея Викторовича" />
      </MetaTags>

      <Header />

      <main className="content loading-page">
        <LoadingSpinner />
      </main>

      <Footer isSocialShown={false} />
    </>
  );
};

export default LoadingPage;
