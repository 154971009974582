import React from 'react';
import PropTypes from 'prop-types';
import MenuSocial from '../menu-social/menu-social';
import './footer';

const Footer = ({isSocialShown}) => {

  return (
    <footer className="footer">
      {isSocialShown ? <div className="footer__menu-social">
        <MenuSocial />
      </div> : null}
      
      <p className="footer__copyright">©2022 «Сайт депутата Сергея Чижова»</p>
    </footer>
  );
};

Footer.propTypes = {
  isSocialShown: PropTypes.bool.isRequired,
};

export default Footer;
