import {loadDumaNews, loadRegionNews, loadNewsById, loadStarted, loadNewsPagesCount, loadGrats, loadGratsByYear, loadSearchResult, getSearchQuery, loadSearchPagesCount} from "../store/action";
import {QueryRoute} from "../utils/const";

export const fetchDumaNews = ({page = 1, items = 10, tag, year}) => (dispatch, _getState, api) => {
  dispatch(loadStarted(true));
  let yearNews = year ? `&year=${year}` : ``;

  api.get(`${QueryRoute.NEWS}?pagesize=${items}&page=${page}&tag=${tag}${yearNews}`)
    .then((response) => {
      dispatch(loadDumaNews(response.data.models));

      dispatch(
        loadNewsPagesCount({
          countNewsPages: response.data.pages,
          activeNewsPage: page
        })
      );

      dispatch(loadStarted(false));
    })
};

export const fetchRegionNews = ({page = 1, items = 10, tag, year}) => (dispatch, _getState, api) => {
  dispatch(loadStarted(true));
  let yearNews = year ? `&year=${year}` : ``;

  api.get(`${QueryRoute.NEWS}?pagesize=${items}&page=${page}&tag=${tag}${yearNews}`)
    .then((response) => {
      dispatch(loadRegionNews(response.data.models));

      dispatch(
        loadNewsPagesCount({
          countNewsPages: response.data.pages,
          activeNewsPage: page,
        })
      );

      dispatch(loadStarted(false));
    })
};

export const fetchNewsById = ({id}) => (dispatch, _getState, api) => {
  dispatch(loadStarted(true));

  api.get(`${QueryRoute.NEWS}/${id}`)
    .then((response) => {
      dispatch(loadNewsById(response.data.model));
      dispatch(loadStarted(false));
    })
};

export const fetchGrats = ({tag}) => (dispatch, _getState, api) => {
  dispatch(loadStarted(true));

  let tagGrats = tag ? `?tag=${tag}` : ``;

  api.get(`${QueryRoute.GRATITUDES}/${tagGrats}`)
    .then((response) => {
      dispatch(loadGrats(response.data.models));
      dispatch(loadStarted(false));
    })
};

export const fetchGratsByYear = ({year}) => (dispatch, _getState, api) => {
  dispatch(loadStarted(true));

  api.get(`${QueryRoute.GRATITUDES}?year=${year}`)
    .then((response) => {
      dispatch(loadGratsByYear(response.data.models));
      dispatch(loadStarted(false));
    })
};

export const fetchSearchResult = ({page = 1, items = 10, tag}) => (dispatch, _getState, api) => {
  dispatch(loadStarted(true));
  dispatch(getSearchQuery(tag));

  api.get(`${QueryRoute.SEARCH}?pagesize=${items}&page=${page}&tag=${tag}`)
    .then((response) => {
      dispatch(loadSearchResult(response.data.models));

      dispatch(
        loadSearchPagesCount({
          countSearchPages: response.data.pages,
          activeSearchPage: page,
        })
      );

      dispatch(getSearchQuery(tag));
      dispatch(loadStarted(false));
    })
};
