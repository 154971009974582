export const AppRoute = {
  ROOT: `/`,
  ABOUT: `/about`,
  GRATITUDES: `/gratitudes`,
  CONTACTS: `/contacts`,
  NEWS_ITEM: `/news/:id`,
  NEWS_LIST_DUMA: `/duma-news`,
  NEWS_LIST_REGION: `/region-news`,
  SEARCH_RESULT: `/search-result`,
  NOT_FOUND: `/404`,
};

export const TabType = {
  DUMA_NEWS: `Новости Госдумы`,
  REG_NEWS: `Работа в регионе`,
};

export const DumaNewsDateYears = [`2024`, `2023`, `2022`, `2021`, `2020`, `2019`, `2018`, `2017`, `2016`, `2015`, `2014`, `2013`, `2012`];
export const RegionNewsDateYears = [`2024`, `2023`, `2022`, `2021`, `2020`, `2019`, `2018`, `2017`, `2016`, `2015`, `2014`, `2013`, `2012`, `2011`, `2010`];
export const GratitudesDateYears = [`2024`, `2023`, `2022`, `2021`, `2020`, `2019`, `2018`, `2017`, `2016`, `2015`, `2014`, `2013`, `2012`, `2011`, `2010`, `2009`, `2008`, `2007`, `2006-2004`, `2003-2001`];

export const QueryRoute = {
  BACKEND_URL: `https://chg-mall.ru/api/dgd`, /* базовый url */
  MEDIA_FILES: `https://chg-mall.ru/`,
  SEARCH: `https://chg-mall.ru/api/dgd/news/search`,
  TAGS: `/tags`,
  NEWS: `/news`,
  GRATITUDES: `/blag`,
  GRATITUDES_TAG: `/tag`,
};

export const gratsTagsMainPage = ['Государственные награды Российской Федерации', 'Благодарности от президента Российской Федерации В.В. Путина', 'Международные награды и поощрения', 'Региональные награды', 'Ведомственные благодарственные письма и поощрения', 'Юбилейные медали и памятные награды']

export const MAIN_TITLE = `Официальный сайт Чижова Сергея Викторовича`;
