export const toggleClassForBody = (isClassAdded) => {
	isClassAdded ? document.body.classList.remove('body-lock') : document.body.classList.add('body-lock');
};

export function getActivePage(val) {
	const params = new URLSearchParams(val);
	return Number(params.get("page")) || 1;
};

export function getActiveYear(val) {
	const params = new URLSearchParams(val);
	return Number(params.get("year")) || null;
};

export function pagination(num, limit, range = 2) {
	const arr = [];
  
	for (let i = 1; i <= limit; i++) {
	  if (i <= range || (i > num - range / 2 && i < num + range / 2) || i > limit - range) {
      if (arr[arr.length - 1] && i !== arr[arr.length - 1] + 1) {
        arr.push("...");
      }

      arr.push(i);
	  }
	}
  
	return arr;
};
