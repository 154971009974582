export const ActionType = {
  LOAD_STARTED: `loadStarted`,
  LOAD_DUMA_NEWS: `loadDumaNews`,
  LOAD_REGION_NEWS: `loadRegionNews`,
  LOAD_NEWS_BY_ID: `loadNewsById`,
  LOAD_NEWS_PAGES_COUNT: `loadNewsPagesCount`,
  LOAD_GRATS: `loadGrats`,
  LOAD_GRATS_BY_YEAR: `loadGratsByYear`,
  LOAD_SEARCH_RESULT: `loadSearchResult`,
  GET_SEARCH_QUERY: `getSearchQuery`,
  LOAD_SEARCH_PAGES_COUNT: `loadSearchPagesCount`,
};

export const loadDumaNews = (data) => ({
  type: ActionType.LOAD_DUMA_NEWS,
  payload: data,
});

export const loadRegionNews = (data) => ({
  type: ActionType.LOAD_REGION_NEWS,
  payload: data,
});

export const loadNewsById = (data) => ({
  type: ActionType.LOAD_NEWS_BY_ID,
  payload: data,
});

export const loadStarted = (status) => ({
  type: ActionType.LOAD_STARTED,
  payload: status,
});

export const loadNewsPagesCount = (data) => ({
  type: ActionType.LOAD_NEWS_PAGES_COUNT,
  payload: data,
});

export const loadGrats = (data) => ({
  type: ActionType.LOAD_GRATS,
  payload: data,
});

export const loadGratsByYear = (data) => ({
  type: ActionType.LOAD_GRATS_BY_YEAR,
  payload: data,
});

export const loadSearchResult = (data) => ({
  type: ActionType.LOAD_SEARCH_RESULT,
  payload: data,
});

export const getSearchQuery = (data) => ({
  type: ActionType.GET_SEARCH_QUERY,
  payload: data,
});

export const loadSearchPagesCount = (data) => ({
  type: ActionType.LOAD_SEARCH_PAGES_COUNT,
  payload: data,
});
