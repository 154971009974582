import React, {lazy, Suspense} from 'react';
import {Router as BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import {createBrowserHistory} from "history";
import {AppRoute} from '../../utils/const';
import LoadingPage from '../pages/loading-page/loading-page';

const MainPage = lazy(() => import('../pages/main-page/main-page'));
const GratitudesPage = lazy(() => import('../pages/gratitudes-page/gratitudes-page'));
const NewsListPage = lazy(() => import('../pages/news-list-page/news-list-page'));
const AboutPage = lazy(() => import('../pages/about-page/about-page'));
const ContactsPage = lazy(() => import('../pages/contacts-page/contacts-page'));
const NewsItemPage = lazy(() => import('../pages/news-item-page/news-item-page'));
const NotFoundPage = lazy(() => import('../pages/not-found-page/not-found-page'));
const SearchResultPage = lazy(() => import('../pages/search-result-page/search-result-page'));

const history = createBrowserHistory();

const App = () => {

  return (
    <BrowserRouter history={history}>
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          <Route exact path={AppRoute.ROOT} component={MainPage} />
          <Route exact path={AppRoute.ABOUT} component={AboutPage} />
          <Route exact path={AppRoute.GRATITUDES} component={GratitudesPage} />
          <Route exact path={AppRoute.CONTACTS} component={ContactsPage} />
          <Route exact path={AppRoute.NEWS_LIST_DUMA} component={NewsListPage} />
          <Route exact path={AppRoute.NEWS_LIST_REGION} component={NewsListPage} />
          <Route exact path={AppRoute.NEWS_ITEM} component={NewsItemPage} />
          <Route exact path={AppRoute.SEARCH_RESULT} component={SearchResultPage} />
          <Route path={AppRoute.NOT_FOUND} component={NotFoundPage} />
          <Redirect to={AppRoute.NOT_FOUND} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
