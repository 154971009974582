import axios from "axios";
import {QueryRoute} from '../utils/const';

const REQUEST_TIMEOUT = 10000;

export const createAPI = () => {
  const api = axios.create({
    baseURL: QueryRoute.BACKEND_URL,
    timeout: REQUEST_TIMEOUT,
  });

  const onSuccess = (response) => response;

  const onFail = (error) => {
    throw error;
  };

  api.interceptors.response.use(onSuccess, onFail);

  return api;
};
