import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {fetchSearchResult} from "../../../api/api-actions";
import {AppRoute} from '../../../utils/const';
import {toggleClassForBody} from '../../../utils/utils';

const SearchForm = ({onLoadSearchResult, isMenuMobile}) => {
  const [searchText, setSearchText] = useState(``);
  const [searchError, setSearchError] = useState(false);
  const history = useHistory();

  const handleTextChange = (evt) => {
    setSearchError(false);
    setSearchText(evt.target.value);
  };

  const handleSearch = () => {
    if (!searchText) {
      setSearchError(true);
      return;
    } else {
      onLoadSearchResult({
        tag: searchText,
        page: 1,
      });
  
      if (isMenuMobile) {
        toggleClassForBody(true);
      }

      history.push(AppRoute.SEARCH_RESULT);
    }

    setSearchText(``);
  };

  

  return (
    <div className="search-form">
      <label className="search-form__label">
        <span>Поиск:</span>
        <input required className={`search-form__input${searchError ? ` search-form__input--error` : ``}`} value={searchText} placeholder="введите свой запрос" onChange={handleTextChange} />
      </label>

      <Link className="search-form__btn" to="#" onClick={handleSearch} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onLoadSearchResult({tag}) {
    dispatch(fetchSearchResult({tag}));
  },
});

export default connect(null, mapDispatchToProps)(SearchForm);
