import React from 'react';
import PropTypes from 'prop-types';

const SocialLink = ({title, path, icon, children}) => {

  return (
    <a className="social-link-element" href={path} target="_blank" aria-label={`Страница депутата в ${title}`}>
      <svg className="social-link-element__logo" width="48" height="40" viewBox="0 0 48 40">
        <use xlinkHref={icon}></use>
      </svg>
      {children}
    </a>
  );
};

SocialLink.propTypes = {
  title: PropTypes.string.isRequired,
  titleToShow: PropTypes.string,
  path: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default SocialLink;
