import React from 'react';
import SocialLink from '../../elements/social-link/social-link';
import telegramLogo from '../../../assets/img/logo-social/telegram.svg'

const MenuSocial = () => {
  return (
    <ul className="menu-social">
      <li className="menu-social__item">
        <SocialLink title={`Вконтакте`} path={`https://vk.com/chizhovsv`} icon={`#soc-vk`} />
      </li>
      <li className="menu-social__item">
        <SocialLink title={`на сайте`} path={`https://www.tiktok.com/@chizhov.s.v`} icon={`#soc-tik`} />
      </li>
      <li className="menu-social__item">
        <SocialLink title={`на сайте`} path={`https://www.ok.ru/chizhov.s.v`} icon={`#soc-ok`} />
      </li>
        <li className="menu-social__item">
            <a target="_blank" className="social-link-element" href='https://t.me/chizhov_s/'>
                <img className="social-link-element__logo" src={telegramLogo} />
            </a>
        </li>
    </ul>
  );
};

export default MenuSocial;
