import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router-dom';
import {AppRoute} from '../../../utils/const';
import MenuSocial from '../menu-social/menu-social';
import {toggleClassForBody} from '../../../utils/utils';
import SearchForm from '../../blocks/search-form/search-form';

const MenuMain = ({match, isMenuMobile, isMenuShown, onSearchBtnClick}) => {
  const onShowMenuBtnClick = () => {
    toggleClassForBody(true);
  };

  return (
    <div className={`menu-main${isMenuMobile ? ` menu-main--mobile` : ``}`}>
      <nav className="menu-main__list-wrapper">
        <ul className="menu-main__list">
          <li className="menu-main__item">
            <Link
              className={match.path === AppRoute.ROOT ? `menu-main__link menu-main__link--active` : `menu-main__link`}
              to={AppRoute.ROOT}
              onClick={onShowMenuBtnClick}>Главная
            </Link>
          </li>
          <li className="menu-main__item">
            <Link
              className={match.path === AppRoute.ABOUT ? `menu-main__link menu-main__link--active` : `menu-main__link`}
              to={AppRoute.ABOUT}
              onClick={onShowMenuBtnClick}>О депутате
            </Link>
          </li>
          <li className="menu-main__item">
            <Link
              className={match.path === AppRoute.NEWS_LIST_DUMA || match.path === AppRoute.NEWS_LIST_REGION || match.path === AppRoute.NEWS_ITEM ? `menu-main__link menu-main__link--active` : `menu-main__link`}
              to={AppRoute.NEWS_LIST_DUMA}
              onClick={onShowMenuBtnClick}>Новости
            </Link>
          </li>
          <li className="menu-main__item">
            <Link
              className={match.path === AppRoute.GRATITUDES ? `menu-main__link menu-main__link--active` : `menu-main__link`}
              to={AppRoute.GRATITUDES}
              onClick={onShowMenuBtnClick}>Благодарности
            </Link>
          </li>
          <li className="menu-main__item">
            <Link
              className={match.path === AppRoute.CONTACTS ? `menu-main__link menu-main__link--active` : `menu-main__link`}
              to={AppRoute.CONTACTS}
              onClick={onShowMenuBtnClick}>Контакты
            </Link>
          </li>
        </ul>
      </nav>

      <SearchForm isMenuMobile={isMenuMobile} />

      {isMenuMobile && !isMenuShown ? <MenuSocial /> : null}
    </div>
  );
};

MenuMain.propTypes = {
  match: PropTypes.object.isRequired,
  isMenuMobile: PropTypes.bool.isRequired,
  isMenuShown: PropTypes.bool,
};

export default withRouter(memo(MenuMain));
