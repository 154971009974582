import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router-dom';
import {AppRoute} from '../../../utils/const';
import {toggleClassForBody} from '../../../utils/utils';
import MenuMain from '../menu-main/menu-main';

const Header = ({}) => {
  const [isMenuShown, setIsMenuShown] = useState(true);
  const [isMenuMobile, setIsMenuMobile] = useState(false);

  const onLogoClick = () => {
    toggleClassForBody(true);
  };

  const onShowMenuBtnClick = () => {
    setIsMenuShown(!isMenuShown);
    toggleClassForBody(!isMenuShown);
  };

  useEffect(() => {
    if (document.body.clientWidth < 992) {
      setIsMenuMobile(true);
    }
  });

  return (
    <header className="header">
      <div className="header__logo">
        <Link
          className="header__logo-link"
          onClick={onLogoClick}
          to={AppRoute.ROOT}><img src="../../assets/img/logo.svg" alt="Официальный сайт Чижова Сергея Викторовича" width="195" height="50" />
        </Link>
      </div>

      <button className={`header__menu-btn${isMenuMobile && !isMenuShown ? ` header__menu-btn--active` : ``}`} onClick={onShowMenuBtnClick} aria-label="menu"><span></span></button>

      {!isMenuMobile ?
        <div className="header__menu-wrapper">
          <MenuMain isMenuMobile={isMenuMobile} />
        </div> :
        <div className="header__menu-wrapper header__menu-wrapper--mobile">
          <MenuMain isMenuMobile={isMenuMobile} isMenuShown={isMenuShown} onSearchBtnClick={onShowMenuBtnClick} />
        </div>}
    </header>
  );
};

Header.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(Header);
